import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Being a physician and educator for more than 30 years in different environments from the tea and coffee plantations at Valparai in Tamil Nadu, to a professor in various medical universities, I can tell that being amidst the Coronavirus pandemic is a new and one-of-its-kind time.`}</p>
    <p>{`Although there seems to be no scarcity of information on the Novel Coronavirus, I am obliged to introduce COVID-19 in the lens of a doctor who needs to convey facts to the public. `}</p>
    <p>{`COVID-19 is different from other infections in a way that a person can have no sign of cough/cold/fever for 2-14 days after infection, yet they can be transmitting the virus all the while. The infection is transmitted in droplet form - when an infected person speaks/coughs/sneezes, these viruses along with the droplets from their mouth can infect others around them. The droplets can fall on objects making them infectious if hygiene is not maintained. Hence, it's important that we keep distance from all people with known infection or otherwise (at least 3 feet) and wash our hands frequently with soap and water, avoid touching the face, nose, mouth and eyes which are entry points for the virus.`}</p>
    <p>{`Most of the time upto 80-85% the infection is mild to moderate in severity and does not need any treatment other than rest at home, doing all that we do for a simple flu like cold. If one finds that one is not getting better or has difficulty in breathing, a doctor needs to be seen immediately and it is likely treatment needs to be initiated and if severity progresses hospitalization may be required.`}</p>
    <p>{`Children under the age of 5, the elderly and immunocompromised patients are particularly vulnerable to COVID-19 infection and can develop severe illness. With age, the immune system weakens meaning the elderly are not as able as their younger counterparts to fight infections. Social and physical factors can add to the existing risk. Existing medical conditions of the heart, kidney, lungs, and cancer add to the severity of illness in case of infection with COVID-19. Those that need to visit hospitals at a regular basis for treatment are at high risk of exposure to the virus in such a setting. Bedridden elderly who are dependent on nursing aids or domestic help for daily assistance are at risk of exposure from possible carriers of the virus. Elderly people who have restricted mobility due to financial dependence, or live by themselves need to depend on others to stock their home, who are also possible carriers of the virus.`}</p>
    <p>{`We need to repeat this mantra over and over - ‘Prevention is better than cure’ and we can ‘flatten the curve’ and minimize transmission and avoid putting our vulnerable elderly population at risk by simply following clearly defined precautions.`}</p>
    <p>{`Most patients with early mild/moderate severity of COVID-19 or suspicion of COVID-19 infection can be managed at home to certain extent following the precautionary measures, thereby reducing the transmission of infection to elderly or young population in the home. However, it is important to recognize when the severity of illness demands hospitalization and other interventions when shortness of breath is observed.  `}</p>
    <p>{`We have listed some precautions here particularly keeping the elderly in mind.`}</p>
    <h3>{`1. Hygiene practices`}</h3>
    <ul>
      <li parentName="ul">{`Practice hand washing and change clothes every time you come home from outside. `}</li>
      <li parentName="ul">{`Wash hands frequently with soap and water, avoid touching the face, nose, mouth and eyes which are entry points for the virus. `}</li>
      <li parentName="ul">{`Ensure everyone follows the coughing and sneezing etiquettes by coughing/ sneezing into elbow or sleeve`}</li>
      <li parentName="ul">{`Avoid touching surfaces that many people could have touched. Sanitize when possible.`}</li>
      <li parentName="ul">{`It is advised for the elderly to avoid pet handling.`}</li>
    </ul>
    <h3>{`2. Travel related quarantine`}</h3>
    <ul>
      <li parentName="ul">{`One should self quarantine if they have travelled elsewhere or have cold/ flu like symptoms.`}</li>
      <li parentName="ul">{`It means that we do not have people visiting us, including domestic helpers and others coming from outside to avoid risk of transmission from each other.`}</li>
      <li parentName="ul">{`Do not shake hands or hug others, keep a distance of at least 3 feet from others `}</li>
    </ul>
    <h3>{`3. Avoid crowded places`}</h3>
    <p>{`Unnecessary visits to hospitals/labs/airports/ religious gatherings and other social events can raise the chances of acquiring infections especially in children and elderly people with a weak/ developing immune system.`}</p>
    <h3>{`4. Engaging the Elderly during Self Isolation - Physical and Mental wellbeing`}</h3>
    <p>{`Being within the four walls for undefined duration can be difficult and stressful when there is less human interaction. The room that they stay in should be well ventilated and lit. Families can interact with the elderly while following precautionary measures.`}</p>
    <p>{`During this time we can engage with the elderly by giving them a call, speaking to them frequently, providing them with material to read , music to listen to and engage in other indoor activities like yoga and meditation. Eating fresh fruits and vegetables & being well hydrated (water, fresh juice, soup) are essential for maintaining good health.`}</p>
    <p>{`The lockdown is a great time to forge relationships between the older and younger in the family - tell stories of other epidemics they have seen in their time, under different circumstances. Ask them to share recipes, teach crafts that they learnt in their younger days, learning to recycle and repair versus thoughtless buying that is commonly seen nowadays. Make them feel appreciated and valued for their wisdom while also updating them with ongoing events. `}</p>
    <p>{`Communication has become easier with technology - phones, whatsapp and other social media. It might be a good time to help them get updated with basic technology that can be a thoughtful gift of independence - teach them online payment, online grocery shopping, medication shopping and even creative tools like the camera and Tiktok might bring in some humor into the gloomy lockdown mode.`}</p>
    <h3>{`5. Community based assistance to the elderly`}</h3>
    <p>{`Community assistance can be organized for the elderly to check on their needs and assist them with their daily necessities by helping to place online orders.
Payment through apps can minimize the need to touch highly infectious surfaces on money, ATM machines, debit card machines.
Maintaining routine contact with elderly in the community, especially those who live alone `}</p>
    <h3>{`6. Safety measures`}</h3>
    <p>{`Biomedical waste- diapers, used masks, gloves , used tissues to be segregated from general waste and disposed as medical waste.
Vegetables and fruits can be washed as soon as they are received.
Packages can be opened immediately and covers discarded after receipt followed by hand washing
For those who need aid at home, it might be preferable to opt for live-in helpers where possible to prevent transmission.
Avoid panic driven actions as a result of unverified sources of information `}</p>
    <p>{`It is our social responsibility during this COVID-19 crisis to do everything in our strength to prevent the spread of infection. We are all in this together and have to look out for one another amidst one other pandemic which shall pass like many others. We conclude with a beautiful thought encompassing the experience this pandemic has brought upon us.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Even the earth began to heal and when the danger ended `}<br />{`
and people found each other grieved for the dead people `}<br />{`
And they made new choices `}<br />{`
And dreamed of new visions `}<br />{`
And created new ways of life `}<br />{`
And healed the earth completely `}<br />{`
Just as they were healed themselves” - Kitty O’Meara`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      